"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FavoriteLinks_1 = require("./elements/FavoriteLinks");
var AjaxCaller_1 = require("./AjaxCaller");
// wait for page to load
window.addEventListener('load', function () {
    // check if user is logged in
    var init = AjaxCaller_1.AjaxCaller.call('getIdCustomer');
    init.then(function (idCustomer) {
        // load module
        FavoriteLinks_1.FavoriteLinks.initialize();
        FavoriteLinks_1.FavoriteLinks.idCustomer = Number(idCustomer);
    }, function () {
        // console.info('User not logged in, not showing module');
    });
});
