"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Links = void 0;
var Lmnt_1 = require("./../abstracts/Lmnt");
var AjaxCaller_1 = require("./../AjaxCaller");
var FavoriteLinks_1 = require("./FavoriteLinks");
var Links = /** @class */ (function (_super) {
    __extends(Links, _super);
    function Links(selector) {
        var _this = _super.call(this, selector) || this;
        _this.links = _this.instance.querySelectorAll('li');
        for (var i = 0; i < _this.links.length - 1; i++) {
            var link = _this.links[i];
            var linkRemoveButton = link.querySelector('button');
            if (linkRemoveButton) {
                linkRemoveButton.addEventListener('click', _this.removeLink.bind(_this, link));
            }
        }
        return _this;
    }
    Links.prototype.refreshLinks = function (template) {
        // from template get only ul element
        var temp = document.createElement('div');
        temp.innerHTML = template;
        var ul = temp.querySelector('ul');
        if (!ul) {
            throw new Error('ul not found');
        }
        this.instance.innerHTML = ul.innerHTML;
    };
    Links.prototype.removeLink = function (link) {
        var _this = this;
        var id = link.querySelector('button').dataset['id'];
        var dataJson = JSON.stringify({
            id_favoritelink: id
        });
        var removeLink = AjaxCaller_1.AjaxCaller.call('deleteLink', dataJson);
        removeLink.then(function (response) {
            FavoriteLinks_1.FavoriteLinks.showFeedback('Link removed');
            _this.refreshLinks(response);
            if (FavoriteLinks_1.FavoriteLinks.idCustomer) {
                FavoriteLinks_1.FavoriteLinks.refresh();
            }
        });
    };
    return Links;
}(Lmnt_1.Lmnt));
exports.Links = Links;
