"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavoriteLinks = void 0;
var AjaxCaller_1 = require("./../AjaxCaller");
var Links_1 = require("./Links");
var AddLinkButton_1 = require("./AddLinkButton");
var SetTitleInput_1 = require("./SetTitleInput");
var FavoriteLinks = /** @class */ (function (_super) {
    __extends(FavoriteLinks, _super);
    function FavoriteLinks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FavoriteLinks.initialize = function () {
        this.instance = document.getElementById('favoritelinks');
        this.links = new Links_1.Links('#favoritelinks-links');
        this.setTitleInput = new SetTitleInput_1.SetTitleInput('#favoritelinks-set-title-input');
        this.addLinkButton = new AddLinkButton_1.AddLinkButton('#favoritelinks-add-link-button');
        this.addLinkButton.addEventListener('click', this.addLink.bind(this));
        this.feedback = document.getElementById('favoritelinks-feedback');
        if (!this.feedback) {
            throw new Error('feedback not found');
        }
        this.show();
    };
    FavoriteLinks.show = function () {
        this.instance.removeAttribute('hidden');
    };
    FavoriteLinks.refresh = function () {
        this.instance = document.getElementById('favoritelinks');
        this.links = new Links_1.Links('#favoritelinks-links');
        this.setTitleInput = new SetTitleInput_1.SetTitleInput('#favoritelinks-set-title-input');
        this.addLinkButton = new AddLinkButton_1.AddLinkButton('#favoritelinks-add-link-button');
        this.addLinkButton.addEventListener('click', this.addLink.bind(this));
        this.feedback = document.getElementById('favoritelinks-feedback');
        if (!this.feedback) {
            throw new Error('feedback not found');
        }
        this.show();
        // console.info('refreshed');
    };
    FavoriteLinks.addLink = function () {
        var _this = this;
        if (!this.idCustomer) {
            throw new Error('idCustomer not set');
        }
        if (!this.setTitleInput.getTitle()) {
            this.showFeedback('Please enter a title');
            return;
        }
        var dataJson = JSON.stringify({
            id_customer: this.idCustomer,
            link: location.href,
            title: this.setTitleInput.getTitle()
        });
        var addLink = AjaxCaller_1.AjaxCaller.call('addLink', dataJson);
        addLink.then(function (response) {
            _this.links.refreshLinks(response);
            if (FavoriteLinks.idCustomer) {
                FavoriteLinks.refresh();
            }
            FavoriteLinks.showFeedback('Link added');
        }, function () {
            _this.showFeedback('There was a problem adding the link');
        });
    };
    FavoriteLinks.showFeedback = function (message) {
        var _this = this;
        this.feedback.querySelector('div').innerHTML = message;
        this.feedback.classList.add('show');
        setTimeout(function () {
            _this.feedback.classList.remove('show');
        }, 2000);
    };
    return FavoriteLinks;
}(HTMLDivElement));
exports.FavoriteLinks = FavoriteLinks;
