"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AjaxCaller = void 0;
var AjaxCaller = /** @class */ (function () {
    function AjaxCaller() {
    }
    AjaxCaller.call = function (fileName, dataJson) {
        var _this = this;
        if (dataJson === void 0) { dataJson = null; }
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', _this.pathAjax + fileName + '.php');
            xhr.onload = function () {
                if (!xhr.response) {
                    reject(null);
                }
                // console.info('response from: '+fileName+'\n\n', xhr.response);
                resolve(xhr.response);
            };
            if (dataJson) {
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(dataJson);
            }
            else {
                xhr.send();
            }
        });
    };
    AjaxCaller.pathAjax = location.origin + '/modules/favoritelinks/php/ajax/';
    return AjaxCaller;
}());
exports.AjaxCaller = AjaxCaller;
