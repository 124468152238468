"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lmnt = void 0;
var Lmnt = /** @class */ (function () {
    function Lmnt(selector) {
        var instance = document.querySelector(selector);
        if (instance !== null && instance !== undefined) {
            this.instance = instance;
        }
        else {
            throw '\n\nElement \"' + this.constructor.name + '\" not found.\n\nGiven selector -> \"' + selector + "\"";
        }
    }
    Lmnt.prototype.show = function () {
        this.instance.removeAttribute('hidden');
    };
    Lmnt.prototype.hide = function () {
        this.instance.setAttribute('hidden', '');
    };
    Lmnt.prototype.addEventListener = function (event, callback) {
        this.instance.addEventListener(event, callback);
    };
    return Lmnt;
}());
exports.Lmnt = Lmnt;
